export function startCountdown() {
  const planetElement = document.querySelector('[data-ttl][data-created-at]');
  if (!planetElement) return;

  const ttl = parseInt(planetElement.getAttribute('data-ttl'), 10);
  const createdAt = parseInt(planetElement.getAttribute('data-created-at'), 10) * 1000;
  const endTime = createdAt + ttl * 1000;

  function updateCountdown() {
    const now = new Date().getTime();
    const distance = endTime - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (document.getElementById('countdown')) {
      document.getElementById('countdown').innerHTML =
        `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }

    // Update the progress bar
    const progressBar = document.getElementById('progress');
    const totalTime = ttl * 1000;
    const elapsedTime = now - createdAt;
    const remainingTime = totalTime - elapsedTime;
    // Flip the percentage calculation
    const progressPercentage = (remainingTime / totalTime) * 100;

    if (!progressBar) return;

    progressBar.style.width = `${progressPercentage}%`;

    // Change color to red if less than or equal to 1 day remaining
    if (remainingTime <= 86400000) { // 86400000 milliseconds in a day
      progressBar.style.backgroundColor = '#ff0000'; // Red color
    } else {
      progressBar.style.backgroundColor = '#4caf50'; // Original green color
    }

    if (distance < 0) {
      clearInterval(interval);
      document.getElementById('countdown').innerHTML = "EXPIRED";
      document.getElementById('progress').style.width = '0%';
    }
  }

  const interval = setInterval(updateCountdown, 1000);
  updateCountdown();
}
